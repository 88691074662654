@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0 auto;
  font-family: Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}
html{
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.shadow{
  --tw-shadow: 0 3px 15px 0px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.heroText{
  font-family: 'Orbitron', sans-serif !important;
}
.content{
  width: 70%;
  margin: 0 auto;
}
.contentfooter{
  width: 80%;
  margin: 0 auto;
}

.bgimgft{
  background-image: url(./assets/bg/ft1.svg);
  background-repeat: no-repeat;
  background-position: bottom;
}
.container{
  max-width: 1200px;
  width: 80% ;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mySwiper{
  width: 100%;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
  background: #de9151 !important;
}
.navshadow{
  box-shadow: 0px 2px 12px 1px #de9151;
  background-color: transparent;
}

.herofooter {
  position: relative;
  background: #242423;
  color: white;
  height: 80vh;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.hero {
  
  position: relative;
  background: #242423;
  color: white;
  height: 90vh;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.hero h2 {
  position: relative;
  z-index: 1;
  font-family: 'Unbounded', sans-serif;
  font-weight: 700;
  margin: 0 0 10px;
  line-height: 1;
  color: rgba(255, 255, 255, 0.9);
}
.herofooter h2 {
  position: relative;
  z-index: 1;
  font-family: 'Unbounded', sans-serif;
  margin: 0 0 10px;
  line-height: 1;
  color: rgba(255, 255, 255, 0.9);
}
.hero img{
  position: relative;
  z-index: 1;
}
.herofooter img{
  position: relative;
  z-index: 1;
}

.hero p {
  position: relative;
  z-index: 1;
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.5);
  line-height: 1.4;
}
.herofooter p {
  position: relative;
  z-index: 1;
  font-size: 1.1rem;
  color: rgba(255, 245, 245, 0.808);
  line-height: 1.4;
}

/* ========================= */

.waves {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 200px;
  background-color: var(--color);
  box-shadow: inset 0 0 50px #242323;
  transition: 500ms;
}

.waves::before,
.waves::after {
  content: '';
  position: absolute;
  width: 300vw;
  height: 300vw;
  top: -75vw;
  left: 50%;
  transform: translate(-50%, -75%);
}

.waves::before {
  border-radius: 44%;
  background: #de9151;
  animation: waves 8s linear infinite;
}

.waves::after {
  border-radius: 44%;
  background: #1f1e1e;
  animation: waves 15s linear infinite;
}

@keyframes waves {
  0% {
    transform: translate(-50%, -75%) rotate(0deg);
  }
  
  100% {
    transform: translate(-50%, -75%) rotate(360deg);
  }
}